import { Routing } from "../../routes/routing";
import { Modules } from "../enums/modules";

export interface ISidebarData {
  id?: number;
  module: string;
  route: string;
  name: string;
  icon?: string;
  childs?: ISidebarData[];
}

export const sidebarRoutes: ISidebarData[] = [
  {
    module: Modules.Dashboard,
    route: Routing.Dashboard,
    name: "Dashboard",
    icon: "Dashboard",
  },
  {
    module: Modules.Users,
    route: Routing.Users,
    name: "Users",
    icon: "People",
  },
  {
    module: Modules.UserWallet,
    route: Routing.UserWallet,
    name: "UserWallet",
    icon: "Wallet",
  },
  {
    module: Modules.Markets,
    route: Routing.Markets,
    name: "Markets",
    icon: "Store",
  },
  {
    module: Modules.AllMarkets,
    route: Routing.Markets,
    name: "All Markets",
  },
  {
    module: Modules.MarketGameRate,
    route: Routing.MarketGameRates,
    name: "Game Rates",
  },
  {
    module: Modules.MarketResults,
    route: Routing.MarketResults,
    name: "Market Results",
    icon: "PunchClock",
  },
  {
    module: Modules.WithdrawalRequest,
    route: Routing.WithdrawlRequest,
    name: "Withdrawal Request",
    icon: "CurrencyExchangeSharp",
  },
  {
    module: Modules.PaymentGateway,
    route: Routing.PaymentGateway,
    name: "Payment Gateway",
    icon: "AccountBalance",
  },
  {
    module: Modules.MarketProfitLoss,
    route: Routing.ProfitLossMarket,
    name: "Profit/Loss",
    icon: "AttachMoneySharp",
  },
  {
    module: Modules.Cutting,
    route: Routing.Cutting,
    name: "Cutting",
    icon: "AttachMoneySharp",
  },
  {
    module: Modules.DebitCreditAnalysis,
    route: Routing.DebitCreditAnalysis,
    name: "DebitCreditAnalysis",
    icon: "QueryStats",
  },
  {
    module: Modules.Analysis,
    route: Routing.Analysis,
    name: "Analysis",
    icon: "Analytics",
  },
  {
    module: Modules.GoogleAnalysis,
    route: Routing.GoogleAnalysis,
    name: "Google Analysis",
  },
  {
    module: Modules.MarketAnalysis,
    route: Routing.MarketAnalysis,
    name: "Market Analysis",
  },
  {
    module: Modules.UserAnalysis,
    route: Routing.UsersAnalysis,
    name: "User Analysis",
  },
  {
    module: Modules.Feedback,
    route: Routing.Feedback,
    name: "Feedback",
    icon: "Feedback",
  },
  {
    module: Modules.Settings,
    route: Routing.Settings,
    name: "Settings",
    icon: "AdminPanelSettings",
  },
  {
    module: Modules.Banners,
    route: Routing.Banners,
    name: "Banners",
  },
  {
    module: Modules.RolePermission,
    route: Routing.Roles,
    name: "Role Permission",
  },
  {
    module: Modules.WithdrawalConfiguration,
    route: Routing.WithdrawalConfiguration,
    name: "Withdrawal Config",
  },
  {
    module: Modules.Notifications,
    route: Routing.Notifications,
    name: "Notifications",
  },
  {
    module: Modules.CMS,
    route: Routing.CMS,
    name: "CMS",
  },
  {
    module: Modules.AdminUsers,
    route: Routing.AdminUsers,
    name: "Admin Users",
  },
  {
    module: Modules.StarLineMarkets,
    route: Routing.StarlineMarkets,
    name: "Starline Markets",
    icon: "StarHalf",
  },
  {
    module: Modules.StarLineMarketProfitLoss,
    route: Routing.ProfitLossStarlineMarket,
    name: "Profit/Loss",
  },
  {
    module: Modules.StarLineMarketResults,
    route: Routing.StarlineMarketResults,
    name: " Market Results",
  },
  {
    module: Modules.StarLineMarketDashboard,
    route: Routing.StarlineMarketDashboard,
    name: "Starline Dashboard",
  },
  {
    module: Modules.StarLineDashboard,
    route: Routing.StarlineDashboard,
    name: "Dashboard",
  },
  {
    module: Modules.StarLineChart,
    route: Routing.StarlineChart,
    name: 'Starline Chart',
  },
  {
    module: Modules.AllStarLineMarkets,
    route: Routing.StarlineAllMarkets,
    name: "All Markets",
  },
  {
    module: Modules.StarLineMarketGameRate,
    route: Routing.StarlineMarketGameRates,
    name: "Game Rates",
  },
];

