import { configureStore } from '@reduxjs/toolkit'
import authReducer from './slices/authSlice'
import { loadStorage, saveStorage } from '../utils/localStorage';
import { IApplicationState } from '../store/state/app-state';
import { createStateSyncMiddleware } from 'redux-state-sync';
import generalReducer from './slices/generalSlice';


const persistentState: IApplicationState = loadStorage();

const store = configureStore({
    reducer: {
        UserData: authReducer,
        GeneralData: generalReducer,
    },
    preloadedState: persistentState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createStateSyncMiddleware({
        // broadcastChannelOption: { type: 'localstorage' },
    })),
    devTools: true,
});

store.subscribe(() => {
    return saveStorage(store.getState());
});

export default store;