import React, { lazy } from "react";
import { createBrowserRouter, RouterProvider, Navigate } from "react-router-dom";
import { Routing } from "./routing";
import ProtectedRoute from "./ProtectedRoute";


const PublicLayout = lazy(() => import("../layout/PublicLayout"));
const MainLayout = lazy(() => import("../layout/MainLayout"));

const Login = lazy(() => import("../pages/Account/Login"));
const OTPLogin = lazy(() => import("../pages/Account/OTPLogin"));

const Dashboard = lazy(() => import("../pages/Dashboard/Dashboard"));

const Users = lazy(() => import("../pages/Users/Users"));
const UserPassbook = lazy(() => import("../pages/Users/UserPassbook"));
const UserPayments = lazy(() => import("../pages/Users/UserPayments"));
const UserDebitCredit = lazy(() => import("../pages/Users/UserDebitCredit"));
const UserDetail = lazy(() => import('../pages/Users/UserDetail'));
const UserAnalysis = lazy(() => import('../pages/Users/UserAnalysis'));


const UserWallet = lazy(() => import("../pages/UserWallet/UserWallet"));

const AllMarkets = lazy(() => import("../pages/Markets/AllMarkets"));
const MarketForm = lazy(() => import('../pages/Markets/MarketForm'));
const GameRates = lazy(() => import("../pages/GameRate/GameRates"));

const MarketResults = lazy(() => import("../pages/Markets/MarketResults"));
const MarketResultDetails = lazy(() => import("../pages/Markets/MarketResultDetails"));

const WithdrawalRequest = lazy(() => import("../pages/WithdrawalRequest/WithdrawlRequest"));
const PaymentGateway = lazy(() => import("../pages/PaymentGateway/PaymentGateway"));

const MarketProfitLoss = lazy(() => import("../pages/ProfitLoss/MarketProfitLoss"));
const Cutting = lazy(() => import("../pages/ProfitLoss/Cutting"));

const DebitCreditAnalysis = lazy(() => import("../pages/DebitCreditAnalysis/DebitCreditAnalysis"));

const MarketAnalysis = lazy(() => import("../pages/Analysis/MarketAnalysis"));
const MarketAnalysisList = lazy(() => import('../pages/Analysis/MarketAnalysisList'));
const GoogleAnalysis = lazy(() => import("../pages/Analysis/GoogleAnalysis"));
const UsersAnalysis = lazy(() => import("../pages/Analysis/UsersAnalysis"));

const Feedback = lazy(() => import("../pages/Feedback/Feedback"));

const Banners = lazy(() => import("../pages/Banners/Banners"));
const Roles = lazy(() => import("../pages/Role/Roles"));
const RolePermissionForm = lazy(() => import("../pages/Role/RolePermissionForm"));
const RolePermissions = lazy(() => import("../pages/Role/RolePermissions"));
const WithdrawlRequestConfiguration = lazy(() => import("../pages/WithdrawalRequest/WithdrawlRequestConfiguration"));
const Notifications = lazy(() => import("../pages/Notifications/Notifications"));
const CMS = lazy(() => import("../pages/CMS/CMS"));
const AdminUsers = lazy(() => import("../pages/AdminUsers/AdminUsers"));
const AdminUserDetails = lazy(() => import('../pages/AdminUsers/AdminUserDetails'));

const StarlineMarketProfitLoss = lazy(() => import("../pages/ProfitLoss/StarlineMarketProfitLoss"));
const StarlineMarketResult = lazy(() => import("../pages/Starline-Market/StarlineMarketResult"));
const StarlineMarketForm = lazy(() => import("../pages/Starline-Market/StarlineMarketForm"));
const StarlineMarketDashboard = lazy(() => import("../pages/Dashboard/StarlineMarketDashboard"));
const StarlineChart = lazy(() => import("../pages/Starline-Market/StarlineChart"));
const StarlineAllMarkets = lazy(() => import("../pages/Starline-Market/StarlineMarkets"));
const StarlineMarketResultDetails = lazy(() => import("../pages/Starline-Market/StarlineMarketResultDetails"));

const ErrorPage = lazy(() => import("../pages/Account/ErrorPage"));
const NotFound = lazy(() => import("../pages/Account/NotFound"));


const privateRoute = (Element: any, props?: any) => {
  return <ProtectedRoute element={props ? <Element {...props} /> : <Element />} />;
};

const routes = createBrowserRouter([
  {
    path: "/",
    element: <Navigate to={Routing.Login} />,
  },
  {
    path: Routing.Login,
    element: (<PublicLayout><Login /></PublicLayout>),
  },
  {
    path: Routing.OTPLogin,
    element: (<PublicLayout><OTPLogin /></PublicLayout>),
  },
  {
    path: Routing.ErrorPage,
    element: (<PublicLayout><ErrorPage /></PublicLayout>),
  },
  {
    path: Routing.Dashboard,
    element: <MainLayout>{privateRoute(Dashboard)}</MainLayout>,
  },
  {
    path: Routing.Users,
    element: <MainLayout>{privateRoute(Users)}</MainLayout>,
  },
  {
    path: Routing.UserPassbook,
    element: <MainLayout>{privateRoute(UserPassbook)}</MainLayout>,
  },
  {
    path: Routing.UserPayments,
    element: <MainLayout>{privateRoute(UserPayments)}</MainLayout>,
  },
  {
    path: Routing.UserDebitCredit,
    element: <MainLayout>{privateRoute(UserDebitCredit)}</MainLayout>,
  },
  {
    path: Routing.UserDetail,
    element: <MainLayout>{privateRoute(UserDetail)}</MainLayout>,
  },
  {
    path: Routing.UserAnalysis,
    element: <MainLayout>{privateRoute(UserAnalysis)}</MainLayout>,
  },
  {
    path: Routing.UserWallet,
    element: <MainLayout>{privateRoute(UserWallet)}</MainLayout>,
  },
  {
    path: Routing.Markets,
    element: <MainLayout>{privateRoute(AllMarkets)}</MainLayout>,
  },
  {
    path: Routing.MarketAddEdit,
    element: <MainLayout>{privateRoute(MarketForm)}</MainLayout>,
  },
  {
    path: Routing.MarketGameRates,
    element: <MainLayout>{privateRoute(GameRates, { isStarline: false })}</MainLayout>,
  },
  {
    path: Routing.MarketResults,
    element: <MainLayout>{privateRoute(MarketResults)}</MainLayout>,
  },
  {
    path: Routing.MarketResultDetails,
    element: <MainLayout>{privateRoute(MarketResultDetails)}</MainLayout>,
  },
  {
    path: Routing.WithdrawlRequest,
    element: <MainLayout>{privateRoute(WithdrawalRequest)}</MainLayout>,
  },
  {
    path: Routing.PaymentGateway,
    element: <MainLayout>{privateRoute(PaymentGateway)}</MainLayout>,
  },
  {
    path: Routing.ProfitLossMarket,
    element: <MainLayout>{privateRoute(MarketProfitLoss)}</MainLayout>,
  },
  {
    path: Routing.Cutting,
    element: <MainLayout>{privateRoute(Cutting)}</MainLayout>,
  },
  {
    path: Routing.DebitCreditAnalysis,
    element: <MainLayout>{privateRoute(DebitCreditAnalysis)}</MainLayout>,
  },
  {
    path: Routing.GoogleAnalysis,
    element: <MainLayout>{privateRoute(GoogleAnalysis)}</MainLayout>,
  },
  {
    path: Routing.MarketAnalysis,
    element: <MainLayout>{privateRoute(MarketAnalysis)}</MainLayout>,
  },
  {
    path: Routing.MarketAnalysisList,
    element: <MainLayout>{privateRoute(MarketAnalysisList)}</MainLayout>,
  },
  {
    path: Routing.UsersAnalysis,
    element: <MainLayout>{privateRoute(UsersAnalysis)}</MainLayout>,
  },
  {
    path: Routing.Feedback,
    element: <MainLayout>{privateRoute(Feedback)}</MainLayout>,
  },
  {
    path: Routing.Banners,
    element: <MainLayout>{privateRoute(Banners)}</MainLayout>,
  },
  {
    path: Routing.Roles,
    element: <MainLayout>{privateRoute(Roles)}</MainLayout>,
  },
  {
    path: Routing.RolePermission,
    element: <MainLayout>{privateRoute(RolePermissionForm)}</MainLayout>,
  },
  {
    path: Routing.RolePermissions,
    element: <MainLayout>{privateRoute(RolePermissions)}</MainLayout>,
  },
  {
    path: Routing.WithdrawalConfiguration,
    element: <MainLayout>{privateRoute(WithdrawlRequestConfiguration)}</MainLayout>,
  },
  {
    path: Routing.Notifications,
    element: <MainLayout>{privateRoute(Notifications)}</MainLayout>,
  },
  {
    path: Routing.CMS,
    element: <MainLayout>{privateRoute(CMS)}</MainLayout>,
  },
  {
    path: Routing.AdminUsers,
    element: <MainLayout>{privateRoute(AdminUsers)}</MainLayout>,
  },
  {
    path: Routing.AdminUserDetails,
    element: <MainLayout>{privateRoute(AdminUserDetails)}</MainLayout>,
  },
  {
    path: Routing.ProfitLossStarlineMarket,
    element: <MainLayout>{privateRoute(StarlineMarketProfitLoss)}</MainLayout>,
  },
  {
    path: Routing.StarlineMarketResults,
    element: <MainLayout>{privateRoute(StarlineMarketResult)}</MainLayout>,
  },
  {
    path: Routing.StarlineDashboard,
    element: <MainLayout>{privateRoute(StarlineMarketDashboard)}</MainLayout>,
  },
  {
    path: Routing.StarlineChart,
    element: <MainLayout>{privateRoute(StarlineChart)}</MainLayout>,
  },
  {
    path: Routing.StarlineMarketGameRates,
    element: <MainLayout>{privateRoute(GameRates, { isStarline: true })}</MainLayout>,
  },
  {
    path: Routing.StarlineAllMarkets,
    element: <MainLayout>{privateRoute(StarlineAllMarkets)}</MainLayout>,
  },
  {
    path: Routing.StarlineMarketAddEdit,
    element: <MainLayout>{privateRoute(StarlineMarketForm)}</MainLayout>,
  },
  {
    path: Routing.StarlineMarketResultDetails,
    element: <MainLayout>{privateRoute(StarlineMarketResultDetails)}</MainLayout>,
  },
  {
    path: "*",
    element: (<PublicLayout><NotFound /></PublicLayout>),
  },
]);

const AppRouting = () => {
  return <RouterProvider router={routes} />;
};

export default AppRouting;
