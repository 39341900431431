import { Suspense } from "react";
import { Provider } from "react-redux";
import store from "./store/store";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ThemeProvider } from "@mui/material/styles";
import portalTheme from "./theme/App.theme";
import AppRouting from "./routes/AppRouting";
// import Loader from "./layout/Loader";
import Spinner from "./layout/Spinner";
import './assets/styles/Global/spacer.css';
import './assets/styles/Global/style.css';

function App() {
  return (
    <Provider store={store}>
      <Suspense fallback={<Spinner />}>
        <ToastContainer limit={3} className="toaster" hideProgressBar={true} />
        {/* <Loader /> */}
        <ThemeProvider theme={portalTheme}>
          <AppRouting />
        </ThemeProvider>
      </Suspense>
    </Provider>
  );
}

export default App;
