const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;

export const Routing = {
  Login: `${PATH_PREFIX}/login`,
  OTPLogin: `${PATH_PREFIX}/otp`,
  ForgotPassword: `${PATH_PREFIX}/forgot-password`,
  ResetPassword: `${PATH_PREFIX}/reset-password`,

  Dashboard: `${PATH_PREFIX}/dashboard`,

  Users: `${PATH_PREFIX}/users`,
  UserDetail: `${PATH_PREFIX}/users/:id?`,
  UserPassbook: `${PATH_PREFIX}/users/pass-book/:id?`,
  UserPayments: `${PATH_PREFIX}/users/payments/:id?`,
  UserDebitCredit: `${PATH_PREFIX}/users/debit-credit/:id?`,
  UserAnalysis: `${PATH_PREFIX}/users/analysis/:id?`,

  UserWallet: `${PATH_PREFIX}/user-wallet`,

  Markets: `${PATH_PREFIX}/markets`,
  MarketAddEdit: `${PATH_PREFIX}/markets/manage/:id?`,
  MarketGameRates: `${PATH_PREFIX}/markets/game-rates`,

  MarketResults: `${PATH_PREFIX}/market-result`,
  MarketResultDetails: `${PATH_PREFIX}/market-result/:id?`,

  WithdrawlRequest: `${PATH_PREFIX}/withdrawl-request`,
  PaymentGateway: `${PATH_PREFIX}/payment-gateway`,

  ProfitLossMarket: `${PATH_PREFIX}/market-profit-loss`,
  Cutting: `${PATH_PREFIX}/cutting`,

  DebitCreditAnalysis: `${PATH_PREFIX}/debit-credit-analysis`,

  Analysis: `${PATH_PREFIX}/analysis`,
  GoogleAnalysis: `${PATH_PREFIX}/analysis/google-analysis`,
  MarketAnalysis: `${PATH_PREFIX}/analysis/market-analysis`,
  MarketAnalysisList: `${PATH_PREFIX}/analysis/market-analysis/:id?`,
  UsersAnalysis: `${PATH_PREFIX}/analysis/users-analysis`,

  Feedback: `${PATH_PREFIX}/feedback`,

  Settings: `${PATH_PREFIX}/settings`,
  Banners: `${PATH_PREFIX}/settings/banners`,
  Roles: `${PATH_PREFIX}/settings/roles`,
  RolePermission: `${PATH_PREFIX}/settings/roles/:id?`,
  RolePermissions: `${PATH_PREFIX}/settings/permissions`,
  WithdrawalConfiguration: `${PATH_PREFIX}/settings/configuration`,
  Notifications: `${PATH_PREFIX}/settings/notifications`,
  CMS: `${PATH_PREFIX}/settings/cms`,
  AdminUsers: `${PATH_PREFIX}/settings/admin-users`,
  AdminUserDetails: `${PATH_PREFIX}/settings/admin-users/:id?`,

  StarlineMarkets: `${PATH_PREFIX}/starline-markets`,

  ProfitLossStarlineMarket: `${PATH_PREFIX}/starline-markets/starline-profit-loss`,

  StarlineMarketResults: `${PATH_PREFIX}/starline-markets/market-result`,
  StarlineMarketResultDetails: `${PATH_PREFIX}/starline-markets/market-result/:id?`,

  StarlineMarketDashboard: `${PATH_PREFIX}/starline-markets/starline-dashboard`,
  StarlineDashboard: `${PATH_PREFIX}/starline-markets/starline-dashboard/dashboard`,
  StarlineChart: `${PATH_PREFIX}/starline-markets/starline-dashboard/charts`,
  StarlineAllMarkets: `${PATH_PREFIX}/starline-markets/starline-dashboard/all-markets`,
  StarlineMarketGameRates: `${PATH_PREFIX}/starline-markets/starline-dashboard/game-rates`,
  StarlineMarketAddEdit: `${PATH_PREFIX}/starline-markets/manage/:id?`,

  ErrorPage: `${PATH_PREFIX}/error`,
  NotFound: `${PATH_PREFIX}/not-found`,
};
