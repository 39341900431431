import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { CssBaseline } from "@mui/material";
import { StyledEngineProvider } from "@mui/styled-engine";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <StyledEngineProvider injectFirst>
    <CssBaseline />
    {/* <React.StrictMode> */}
    <App />
    {/* </React.StrictMode> */}
  </StyledEngineProvider>
);
reportWebVitals();
