import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ISidebarData, sidebarRoutes, } from "../../shared/constants/sidebar-data";
import { IAuthState } from "../state/app-state";
import { ILoginResponseModel } from "../../models/account";
import { IRoutesModel } from "../../models/routes";

const initialState: IAuthState = {
  id: 0,
  userName: "",
  fullName: "",
  role: "",
  token: "",
  permissions: [],
  is2FA: false,
};

const authSlice = createSlice({
  name: "auth",
  initialState,
  reducers: {
    adminLogin: (state, action: PayloadAction<ILoginResponseModel>) => {
      state.id = action.payload.id;
      state.token = action.payload.token;
      state.is2FA = false;
    },

    otpLogin: (
      state: IAuthState,
      action: PayloadAction<ILoginResponseModel>
    ) => {
      state.id = action.payload.id;
      state.userName = action.payload.userName;
      state.fullName = action.payload.fullName;
      state.role = action.payload.role;
      state.token = action.payload.token;
      state.is2FA = true;
    },

    adminLogout: () => initialState,

    updatePermission: (state, action: PayloadAction<IRoutesModel[]>) => {
      const permissions = action.payload?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
      const newPermission: ISidebarData[] = permissions?.map((route) => {
        const module = sidebarRoutes?.find((x) => x?.module === route?.name);
        if (route?.childRoute?.length > 0) {
          const childRoutes = route?.childRoute?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
          return {
            ...module,
            id: route?.id,
            childs: childRoutes
              ?.map((routeChild) => {
                const child = sidebarRoutes?.find((x) => x?.module === routeChild?.name);
                if (routeChild?.childRoute?.length > 0) {
                  const subChildRoutes = routeChild?.childRoute?.sort((a, b) => (a?.priority > b?.priority ? 1 : -1));
                  return {
                    ...child,
                    id: routeChild?.id,
                    childs: subChildRoutes
                      ?.map((subRouteChild) => {
                        const child = sidebarRoutes?.find((x) => x?.module === subRouteChild?.name);
                        if (child?.name) {
                          return { id: subRouteChild?.id, ...child };
                        }
                      })
                      .filter((x) => !!x?.name),
                  }
                }
                if (child?.name) {
                  return { id: routeChild?.id, ...child };
                }
              })
              .filter((x) => !!x?.name),
          };
        } else {
          return { id: route?.id, ...module };
        }
      });
      state.permissions = newPermission?.filter((x) => !!x?.name) || [];
    },
  },
});

export const { adminLogin, otpLogin, adminLogout, updatePermission } =
  authSlice.actions;

export default authSlice.reducer;
