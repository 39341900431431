import { createTheme } from '@mui/material/styles';

let portalTheme = createTheme({
    palette: {
        primary: {
            main: '#1e293b',
            dark: '#1e293b',
            light: '#374865',
        },
        secondary: {
            contrastText: '#fff',
            main: '#1e293b',
            light: '#D1D1D1',
            dark: '#9E9E9E',
        },
        text: {
            primary: '#3E3E3E',
            disabled: '#A3A3A3',
        },
        info: {
            main: '#9BB6D5',
            contrastText: '#6AB7FF',
            light: '#fca63c',
        },
        error: {
            main: '#FF5353',
            light: '#FF8A8A',
            dark: '#ff0000',
        },
        warning: {
            main: '#FDA53D',
            light: '#F8824F',
        },
        success: {
            main: '#45D09E',
        },
    },
});

portalTheme = createTheme(portalTheme, {
    MuiCssBaseline: {
        styleOverrides: {
            '*': {
                boxSizing: 'border-box',
            },
            html: {
                MozOsxFontSmoothing: 'grayscale',
                WebkitFontSmoothing: 'antialiased',
                height: '100%',
                width: '100%',
            },
            body: {
                height: '100%',
            },
            a: {
                textDecoration: 'none',
                color: 'inherit',
            },
            '#root': {
                height: '100%',
            },
            '#nprogress .bar': {
                zIndex: '2000 !important',
            },
        },
    },
    // Typography
    typography: {
        fontFamily: 'Roboto, sans-serif',
        h1: {
            fontSize: '1.8rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2.13333333333rem' },
        },
        h2: {
            fontSize: '1.3rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '1.87500rem' },
        },
        h3: {
            fontSize: '1.7rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2.5rem' },
        },
        h4: {
            fontSize: '1.6rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
            [portalTheme.breakpoints.up('md')]: { fontSize: '2rem' },
        },
        h5: {
            fontSize: '1.33333333333rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        h6: {
            fontSize: '1.06666666667rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        body1: {
            fontSize: '1rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        body2: {
            fontSize: '0.933333333rem',
            lineHeight: 1.2,
            fontWeight: 400,
            letterSpacing: 0,
        },
        caption: {
            fontSize: '0.8rem',
            lineHeight: 1.06666667,
            fontWeight: 400,
            letterSpacing: 0,
        },
    },
    components: {
        MuiAppBar: {
            styleOverrides: {
                root: {
                    backgroundColor: [portalTheme.palette.secondary.contrastText],
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
                    marginBottom: 20,
                    borderRadius: 0,
                },
            },
        },

        MuiToolbar: {
            styleOverrides: {
                root: {
                    minHeight: 'auto!important',
                },
            },
        },

        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: -2,
                    transformOrigin: 'left center',
                    maxWidth: 'calc(100% - 38px)',
                    lineHeight: '1em',
                    '&.Mui-focused': {
                        top: 2
                    },
                },
                shrink: {
                    maxWidth: '100%',
                    top: 2
                },
            },
        },

        MuiBadge: {
            styleOverrides: {
                badge: {
                    top: '10%',
                    right: '10%',
                    fontSize: 11,
                    lineHeight: '13px',
                    fontWeight: 300,
                    zIndex: 0,
                    padding: '0 3px',
                },
                colorError: {
                    backgroundColor: [portalTheme.palette.warning.light],
                },
            },
        },

        MuiOutlinedInput: {
            styleOverrides: {
                root: {
                    input: {
                        padding: 10,
                        height: 24,
                        color: [portalTheme.palette.text.primary],
                    },
                    '&:hover': {
                        fieldset: {
                            borderColor: [portalTheme.palette.primary.main] + '!important',
                        },
                    },
                },
                notchedOutline: {
                    borderColor: [portalTheme.palette.secondary.light],
                },
            },
        },

        MuiInputBase: {
            styleOverrides: {
                adornedEnd: {
                    paddingRight: '18px!important',
                },
            },
        },

        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: -8,
                },
            },
        },

        MuiPaper: {
            styleOverrides: {
                root: {
                    background: [portalTheme.palette.secondary.contrastText],
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1)',
                    borderRadius: 5,
                    '&.select-wrapper': {
                        maxHeight: 220,
                    },
                },
            },
        },

        MuiInput: {
            styleOverrides: {
                root: {
                    '&:before': {
                        borderBottom: '1px solid #0000006b!important',
                    },
                    '&:hover': {
                        '&:before': {
                            borderBottomColor: [portalTheme.palette.primary.main] + '!important',
                        },
                    },
                },
            },
        },

        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                },
            },
        },

        MuiSelect: {
            styleOverrides: {
                select: {
                    paddingTop: 13,
                    paddingBottom: 6.5,
                    paddingLeft: 13,
                    height: 24,
                },
                icon: {
                    width: 5,
                    height: 5,
                    borderBottom: 'solid 1.5px #A3A3A3',
                    borderRight: 'solid 1.5px #A3A3A3',
                    transform: 'rotate(45deg)',
                    transition: 'all 0.2s ease',
                    right: 13,
                    top: 'calc(50% - 4px)',
                    path: {
                        opacity: 0,
                    },
                },
                iconOpen: {
                    transform: 'rotate(225deg)',
                },
            },
        },

        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: 'none',
                    fontWeight: 400,
                   
                    boxShadow: 'none!important',
                },
                containedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                containedSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                containedSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                textSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 6,
                    paddingBottom: 6,
                },
                textSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 10,
                    paddingBottom: 10,
                },
                textSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 15,
                    paddingBottom: 15,
                },
                outlinedSizeSmall: {
                    fontSize: 12,
                    lineHeight: '15px',
                    paddingTop: 5,
                    paddingBottom: 5,
                },
                outlinedSizeMedium: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 9,
                    paddingBottom: 9,
                },
                outlinedSizeLarge: {
                    fontSize: 16,
                    lineHeight: '20px',
                    paddingTop: 14,
                    paddingBottom: 14,
                },

                startIcon: {
                    marginRight: 6,
                    marginLeft: -2,
                },
            },
        },

        MuiIconButton: {
            styleOverrides: {
                sizeMedium: {
                    padding: 4,
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 5,
                    },
                },
            },
        },

        MuiTypography: {
            styleOverrides: {
                root: {
                    whiteSpace: 'normal',
                },
            },
        },

        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    lineHeight: 1.3,
                },
            },
        },

        MuiMenuItem: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                },
            },
        },

        MuiTable: {
            styleOverrides: {
                root: {
                    borderCollapse: 'separate',
                    borderRadius: 5,
                    marginBottom: 4,

                },

            },
        },

        MuiTableCell: {
            styleOverrides: {
                root: {
                    '& .MuiCheckbox-root': {
                        marginLeft: -8,
                        marginTop: -8,
                        marginBottom: -8,
                    },
                },
                head: {
                    padding: '17px 10px',
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    color: '#6a7c9a !important',
                    fontWeight: 600,
                    whiteSpace: 'nowrap',
                    background: "rgb(248, 249, 251)",

                },
                body: {
                    padding: '3px 10px',
                    fontSize: '1rem',
                    lineHeight: 1.2,
                    fontWeight: 400,
                    color: [portalTheme.palette.text.primary],
                    height: 33,
                    whiteSpace: 'nowrap',
                    textOverflow: 'ellipsis',
                    overflow: 'hidden',
                },
            },
        },

        MuiTableRow: {
            styleOverrides: {
                root: {
                    '&.Mui-selected': {
                        backgroundColor: 'rgba(0, 151, 169, 0.2)',
                    },
                    '& .MuiTableCell-body': {
                        borderBottom: "none",
                    },
                },
            },
        },

        MuiTableSortLabel: {
            styleOverrides: {
                icon: {
                    opacity: 1,
                    fill: [portalTheme.palette.secondary.light],
                },
                root: {
                    '&.Mui-active .MuiTableSortLabel-icon': {
                        fill: [portalTheme.palette.text.primary] + '!important',
                    },
                },
            },
        },

        MuiPaginationItem: {
            styleOverrides: {
                sizeSmall: {
                    fontSize: 15,
                    lineHeight: '18px',
                    color: [portalTheme.palette.text.primary],
                    padding: '6px',
                    height: 28,
                    minWidth: 28,
                    margin: '0 4px',
                    '&.Mui-selected': {
                        color: [portalTheme.palette.secondary.contrastText],
                        backgroundColor: [portalTheme.palette.primary.main],
                        '&:hover': {
                            backgroundColor: [portalTheme.palette.primary.dark],
                        },
                    },
                    '& .MuiPaginationItem-icon': {
                        width: 22,
                        height: 22,
                    },
                },
            },
        },

        MuiBox: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    backgroundColor: [portalTheme.palette.secondary.contrastText],
                    padding: 15,
                    boxShadow: '0px 0px 10px 1px rgba(0, 0, 0, 0.1);',
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 20,
                    },
                },
            },
        },

        MuiChip: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                    height: 23,
                    fontSize: 13,
                    fontWeight: 500,
                    lineHeight: '15px',
                    backgroundColor: '#1e293b',
                    '&.rounded': {
                        borderRadius: 24,
                    },
                },
                sizeMedium: {
                    borderRadius: 24,
                    minHeight: 37,
                    fontWeight: 400,
                },
                deleteIcon: {
                    margin: '0 9px 0 -3px',
                },
                labelMedium: {
                    fontSize: 15,
                    lineHeight: 1.2,
                    padding: '9px 9px 9px 12px',
                },
                label: {
                    padding: '4px 6px',
                },
                colorInfo: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                colorSuccess: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                colorWarning: {
                    color: [portalTheme.palette.secondary.contrastText],
                },
                filled: {
                    '&.MuiChip-filledInfo': {
                        backgroundColor: [portalTheme.palette.info.light] + '!important',
                    },
                    '&.MuiChip-filledSuccess': {
                        backgroundColor: [portalTheme.palette.success.light] + '!important',
                    },
                    '&.MuiChip-filledError': {
                        backgroundColor: [portalTheme.palette.error.light] + '!important',
                    },
                    '&.MuiChip-filledSecondary': {
                        backgroundColor: [portalTheme.palette.secondary.dark] + '!important',
                    },
                },
            },
        },

        MuiListSubheader: {
            styleOverrides: {
                root: {
                    fontWeight: 600,
                    fontSize: '1rem',
                    lineHeight: '32px',
                },
            },
        },

        MuiSwitch: {
            styleOverrides: {
                root: {
                    marginLeft: -9,
                },
                track: {
                    opacity: 0.2,
                    height: 12,
                },
                thumb: {
                    width: 18,
                    height: 18,
                    color: '#1e293b',
                },
                switchBase: {
                    '&.Mui-disabled': {
                        '& .MuiSwitch-thumb': {
                            backgroundColor: [portalTheme.palette.text.disabled],
                        },
                        '& + .MuiSwitch-track': {
                            backgroundColor: [portalTheme.palette.secondary.dark],
                        },
                    },
                },
            },
        },

        MuiMenu: {
            styleOverrides: {
                list: {
                    '&.multi-select-menu': {
                        '& .MuiMenuItem-root': {
                            padding: '2px 8px',
                        },
                    },
                },
            },
        },

        MuiAutocomplete: {
            styleOverrides: {
                option: {
                    color: [portalTheme.palette.text.primary],
                    padding: '8px 20px!important',
                    fontSize: '0.933333rem',
                    lineHeight: 1.2,
                    '& .MuiTypography-root': {
                        fontSize: '0.93333333rem',
                        lineHeight: 1.2,
                    },
                    '& .highlighted-option': {
                        fontSize: '0.93333333rem',
                        lineHeight: 1.2,
                        fontWeight: 500,
                    },
                },
            },
        },

        MuiCard: {
            styleOverrides: {
                root: {
                    backgroundColor: [portalTheme.palette.secondary.contrastText],
                    boxShadow: '0 0 10px rgba(0, 0, 0, 0.10)',
                    borderRadius: 5,
                    minHeight: '100%',
                    '& .MuiCardHeader-root': {
                        padding: '20px 20px 14px',
                        '& .MuiCardHeader-title': {
                            display: 'flex',
                            flexWrap: 'wrap',
                            lineHeight: 1.4,
                            fontSize: 18,
                        },
                        '& .title': {
                            fontSize: 18,
                            lineHeight: 1.2,
                        },
                        '& .MuiCardHeader-action a': {
                            display: 'block',
                            marginTop: 4,
                            marginBottom: 4,
                            marginRight: 8,
                        },
                    },
                    '& .MuiCardContent-root': {
                        padding: '0 20px 20px',
                        '&:last-child': {
                            paddingBottom: 20,
                        },
                    },
                },
            },
        },

        MuiDialog: {
            styleOverrides: {
                paper: {
                    backgroundColor: [portalTheme.palette.secondary.contrastText],
                    borderRadius: 5,
                    margin: 15,
                    [portalTheme.breakpoints.up('md')]: {
                        margin: 40,
                    },
                },
                paperWidthSm: {
                    maxWidth: 420,
                    width: 420,
                },
                paperWidthMd: {
                    maxWidth: 600,
                    width: 600,
                },
                paperWidthLg: {
                    maxWidth: 1056,
                    width: 1056,
                },
                paperWidthXl: {
                    maxWidth: 1400,
                    width: 1400,
                },
            },
        },

        // Dialog
        MuiDialogContent: {
            styleOverrides: {
                root: {
                    padding: '20px 20px 0!important',
                    minHeight: 60,
                    overflow: 'auto',
                    '&.no-action': {
                        padding: '20px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '30px!important',
                        },
                    },
                    '&.form-dialog-content': {
                        padding: '20px!important',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '30px!important',
                        },
                    },
                },
            },
        },

        MuiDialogActions: {
            styleOverrides: {
                root: {
                    padding: '16px 20px!important',
                    flexWrap: 'wrap',
                    '&>:first-of-type': {
                        margin: '4px 0',
                    },
                    '&>:not(:first-of-type)': {
                        margin: '4px 0',
                        marginLeft: 8,
                        [portalTheme.breakpoints.up('sm')]: {
                            marginLeft: 20,
                        },
                    },
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: '20px!important',
                        flexWrap: 'normal',
                    },
                    '&.form-dialog-actions': {
                        padding: '16px 0 0!important',
                        flexWrap: 'wrap',
                        [portalTheme.breakpoints.up('sm')]: {
                            padding: '20px 0 0!important',
                            flexWrap: 'normal',
                        },
                    },
                },
            },
        },

        MuiDialogTitle: {
            styleOverrides: {
                root: {
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    padding: '12px 16px',
                    backgroundColor: [portalTheme.palette.primary.main],
                    color: [portalTheme.palette.secondary.contrastText],
                    '& .MuiTypography-root': {
                        fontSize: '1.2rem',
                        lineHeight: 1.3333333,
                    },
                    '& .close-action': {
                        minWidth: 24,
                        margin: '-4px -4px -4px 0',
                    },
                },
            },
        },

        MuiCalendarPicker: {
            styleOverrides: {
                root: {
                    '& .MuiPickersDay-root': {
                        width: 32,
                        height: 32,
                        '& .PrivatePickersSlideTransition-root': {
                            minHeight: 196,
                        },
                    },
                },
            },
        },

        // Tabs
        
        // MuiTabs: {
        //     styleOverrides: {
        //         root: {
        //             minHeight: 'auto',
        //         },
        //     },
        // },

        // MuiTab: {
        //     styleOverrides: {
        //         root: {
        //             padding: '10px 0',
        //             textTransform: 'none',
        //             letterSpacing: 0,
        //             [portalTheme.breakpoints.up('md')]: {
        //                 fontSize: 18,
        //                 lineHeight: '24px',
        //             },
        //         },
        //     },
        // },

        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 'auto',
                    '& .Mui-selected': {
                        zIndex: 1,
                        color: '#ffffff !important',
                    },
                },
                flexContainer: {
                    gap: 10,
                },
                indicator: {
                    height: '100%',
                    borderRadius: 8,
                }
            },
        },

        MuiTab: {
            styleOverrides: {
                root: {
                    padding: '12px 10px',
                    textTransform: 'none',
                    letterSpacing: 0,
                    fontSize: 15,
                    lineHeight: '12px !important',
                    minHeight: '20px !important',
                    transition: 'all 0.1s ease-in-out',
                    [portalTheme.breakpoints.up('md')]: {
                        fontSize: 18,
                        lineHeight: '24px',
                        minHeight: 48,
                    },
                },
            },
        },

        MuiStepper: {
            styleOverrides: {
                root: {
                    marginBottom: 40,
                },
            },
        },
        MuiStepIcon: {
            styleOverrides: {
                root: {
                    [portalTheme.breakpoints.up('sm')]: {
                        width: 50,
                        height: 50,
                    },
                },
            },
        },
        MuiStepConnector: {
            styleOverrides: {
                root: {
                    [portalTheme.breakpoints.up('sm')]: {
                        top: 24,
                        left: ' calc(-50% + 40px)',
                        right: 'calc(50% + 40px)',
                    },
                },
            },
        },

        MuiStepLabel: {
            styleOverrides: {
                root: {
                    '.MuiStepLabel-labelContainer': {
                        [portalTheme.breakpoints.down('md')]: {
                            display: 'none',
                        },
                    },
                },
            },
        },

        MuiToggleButtonGroup: {
            styleOverrides: {
                root: {
                    borderRadius: 5,
                },
            },
        },

        MuiToggleButton: {
            styleOverrides: {
                sizeLarge: {
                    padding: 8,
                    [portalTheme.breakpoints.up('sm')]: {
                        padding: 13,
                    },
                },
                primary: {},
                root: {
                    border: '1px solid',
                    borderColor: [portalTheme.palette.primary.main] + '!important',
                    '&:hover': {
                        backgroundColor: [portalTheme.palette.primary.light] + '!important',
                    },
                    '&.Mui-selected': {
                        backgroundColor: [portalTheme.palette.primary.main] + '!important',
                    },
                },
            },
        },

        MuiRadio: {
            styleOverrides: {
                root: {
                    padding: 7,
                    '& + .MuiFormControlLabel-label': {
                        lineHeight: 1.5,
                    },
                },
            },
        },

        MuiAccordion: {
            styleOverrides: {
                root: {
                    '&.bordered-accordion': {
                        borderRadius: '5px!important',
                        border: '1px solid #D1D1D1',
                        backgroundColor: [portalTheme.palette.primary.light],
                        boxShadow: 'none',
                        transition: 'all 0.2s ease',
                        '&:before': {
                            display: 'none',
                        },
                        '& .MuiAccordionSummary-root': {
                            padding: '0 10px',
                            '&.Mui-expanded': { minHeight: 'auto' },
                        },
                        '& .MuiAccordionSummary-content': {
                            '&.Mui-expanded': { margin: '14px 0' },
                        },
                        '&.Mui-expanded': {
                            margin: '10px 0 0',
                            backgroundColor: [portalTheme.palette.secondary.contrastText],
                            '&:first-of-type': {
                                margin: 0,
                            },
                        },
                        '& .MuiAccordionDetails-root': {
                            padding: 10,
                            borderTop: '1px solid #D1D1D1',
                        },
                    },
                },
            },
        },
    },
});

export default portalTheme;
